import React, {useEffect, useState} from "react";
import Loading from "../../../../components/Loading";
import {Typography, FreepayrollButton, FreepayrollDropbox, Icon, Modal} from "@collegia-partners/ui-kit";
import {useNavigate} from "react-router-dom";
import PayScheduleDeleteDialog from "../../../../components/Pages/PaySchedule/PayScheduleDeleteDialog";
import FPTable from "../../../../components/common/FPTable/FPTable";
import {useListPaySchedules, useUpdatePayScheduleStatus} from "../../../../hooks/pay-schedules";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../../hooks/employer";
import {StickerAlert} from "../../../../components/StickerAlert";

function ConfirmationModal({ isOpen, onClose, title, message, action, isPending = false }) {

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'sm'}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw', padding: '0.93vw' }} >
				<Typography variant="title" size="xmedium" color="black" weight="bold">
					{title}
				</Typography>
				<Typography variant="title" size="medium" color="black" weight={'normal'} >
					{message}
				</Typography>
				<StickerAlert stickerColor={'#f25454'} text={'This action cannot be undone'} mode={'info'} />
				<div style={{ display: 'flex', flexDirection: 'row', gap: '1.83vw', justifyContent: 'flex-end' }}>
					<FreepayrollButton
						size="small"
						height={0.4}
						width={3.5}
						custom
						variant="white-button"
						onClick={onClose}
						isLoading={isPending}
					>
						No
					</FreepayrollButton>
					<FreepayrollButton
						size="small"
						height={0.4}
						width={3.5}
						custom
						variant="primary"
						onClick={action}
						isLoading={isPending}
					>
						Yes
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PaySchedules = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();
	const { employer, isPending: isLoadingEmployer } = useGetEmployer({relations: ['pay_schedules']});
	const { mutateAsync: updateStatus, isPending} = useUpdatePayScheduleStatus();

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false),
		[confirmDeactivate, setConfirmDeactivate] = useState(false),
			[payScheduleSelected, setPayScheduleSelected] = useState({});

	const push = useNavigate();
	
	const columns = [
		{
			text: "Name",
			color: "black",
			key: "name",
		},
		{
			text: "Frequency",
			color: "black",
			key: "frequency_code.freq_name",
		},
		{
			text: "N of Employees",
			color: "black",
			key: "employees_count",
		},
		{
			text: "Status",
			color: "black",
			key: "active",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					color={row.active ? "primary" : "red"}
					weight={"bold"}
				>
					{row.active ? "Active" : "Inactive"}
				</Typography>
			),
		},
		{
			text: "Actions",
			color: "black",
			customRender: (row, index) => (
				<FreepayrollDropbox
					target={
						<button
							onClick={() => setPayScheduleSelected(row)}
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					key={index}
					className={"EmployeeSummaryActions"}
				>
					<button
						onClick={() => {
							push(`/main/company/edit-pay-schedule/${row.id}`);
						}}
						className={"EmployeeSummaryAction"}
					>
						<Icon iconName={"pencil"} width={"13.5vw"}/>
						Edit
					</button>
					<button
						onClick={() => {
							setOpenDeleteDialog(true);
						}}
						disabled={row.not_draft_pay_run !== null}
						className={"EmployeeSummaryAction"}
					>
						<Icon iconName={"delete"} width={"13.5vw"}/>
						Delete
					</button>
					{
						row.active && (
							<button
								onClick={() => {
									setConfirmDeactivate(true);
								}}
								className={"EmployeeSummaryAction"}
							>
								<div style={{
									width: '10px',
									height: '10px',
									borderRadius: '50%',
									backgroundColor: 'green',
									cursor: 'pointer',
								}}
							/>
								Inactivate
							</button>
						)
					}
				</FreepayrollDropbox>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Pay Schedules',
		});
	}, [setLayoutProps]);

	if (isLoadingEmployer || isPending) {
		return <Loading/>;
	}

	return (
		<>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
				<div
					style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Pay Schedules
					</Typography>
					<div style={{ width: "fit-content" }}>
						<FreepayrollButton
							size={"medium"}
							height={1.2}
							custom
							variant={"primary"}
							onClick={() => push("/main/company/add-pay-schedule")}
						>
							Create Pay Schedule
						</FreepayrollButton>
					</div>
				</div>
				<div>
					<FPTable
						headers={columns}
						hookName={useListPaySchedules}
						hookKey={'paySchedules'}
						hookParams={{}}
					/>
				</div>
			</div>
			<ConfirmationModal
				isOpen={confirmDeactivate}
				onClose={() => {
					setConfirmDeactivate(false);
				}}
				title={"Are you sure you want to deactivate this pay schedule?"}
				message={"This action will flag the pay schedule as completed, and no more pay runs will be created for it."}
				action={async () => {
					try {
						await updateStatus({id: payScheduleSelected.id});
						setConfirmDeactivate(false);
					} catch (e) {
						console.error(e);
					}
				}}
				isPending={isPending}
			/>

			<PayScheduleDeleteDialog
				selectedPaySchedule={payScheduleSelected}
				setSelectedPaySchedule={setPayScheduleSelected}
				openDeleteDialog={openDeleteDialog}
				setOpenDeleteDialog={setOpenDeleteDialog}
				paySchedules={employer?.pay_schedules}
			/>
		</>
	);
}

export default PaySchedules;