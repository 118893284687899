import Grid from '@mui/material/Grid';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StepZero } from '../../../components/Pages/BasicSetup/StepZero/StepZero';
import { StepOne } from '../../../components/Pages/BasicSetup/StepOne/StepOne';
import { StepTwo } from '../../../components/Pages/BasicSetup/StepTwo/StepTwo';
import { StyleAppBar } from '../../../components/StyleAppBar';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import collegiaLogo from '../../../images/svg/Logos/Logo-collegia.svg';
import StyleTypography from '../../../components/StyledComponents/StyleTypography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import StepThreeCompliance from "../../../components/Pages/BasicSetup/StepThree/StepThreeCompliance";
import DDLogo from '../../../images/svg/direct-debit-1.svg';
import StyleButton from "../../../components/StyledComponents/StyleButton";
import Request from "../../../utils/Request";
import {useUser} from "../../../context/UserContext";
import {useBasicSetup} from "../../../hooks/employer/useBasicSetup";
import {useGetEmployer} from "../../../hooks/employer";
import {useRetrieveMandateToken} from "../../../hooks/employer/useRetrieveMandateToken";
import {useImportCollegiaData} from "../../../hooks/employer/useImportCollegiaData";
import {FreepayrollButton} from "@collegia-partners/ui-kit";

function _renderSteps(
	step: number,
	stepZero: any,
	setStepZero: any,
	stepOne,
	setStepOne,
	stepTwo,
	setStepTwo,
	stepThree,
	setStepThree,
	setTabIndex,
	basicSetup,
	user
) {
	switch (step) {
		case 0:
			return (
				<StepZero
					stepZero={stepZero}
					setStepZero={setStepZero}
					basicSetup={basicSetup}
					user={user}
				/>
			);
		case 1:
			return (
				<StepOne
					stepOne={stepOne}
					setStepOne={setStepOne}
					basicSetup={basicSetup}
				/>
			);
		case 2:
			return (
				<StepTwo
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					setTabIndex={setTabIndex}
					basicSetup={basicSetup}
				/>
			);
		case 3:
			return (
				<StepThreeCompliance
					stepThree={stepThree}
					setStepThree={setStepThree}
					basicSetup={basicSetup}
				/>
			);
		default:
			return;
	}
}

function backLink(
	tabIndex,
	stepZero,
	stepOne,
	stepTwo,
	stepThree,
	setStepZero,
	setStepOne,
	setStepTwo,
	setStepThree,
	navigate
) {
	switch (tabIndex) {
		case 0:
			if (stepZero.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepZero({
					...stepZero,
					inner_step: stepZero.inner_step - 1,
				});
			}
		case 1:
			if (stepOne.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepOne({ ...stepOne, inner_step: stepOne.inner_step - 1 });
			}
		case 2:
			if (stepTwo.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepTwo({ ...stepTwo, inner_step: stepTwo.inner_step - 1 });
			}
		case 3:
			if (stepThree.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepThree({
					...stepThree,
					inner_step: stepThree.inner_step - 1,
				});
			}
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const BasicSetup = (): JSX.Element => {

	const { employer } = useGetEmployer({ relations: ['collegia_emandate'] });
	const { profileData: user, isPending: isUserLoading } = useUser();

	const [mandateFlowInitiated, setMandateFlowInitiated] = useState(false);
	const [token, setToken] = useState('');
	const [tokenLoading, setTokenLoading] = useState(false);
	const [hasCollegiaAccount, setHasCollegiaAccount] = useState(false);

	const {
		mutate: basicSetup,
		isPending: isBasicLoading
	} = useBasicSetup(setMandateFlowInitiated,setToken,setHasCollegiaAccount);

	const { mutate: importCollegiaData, isPending: isImportingCollegia } = useImportCollegiaData(
		setMandateFlowInitiated,
		setToken
	);

	const { mandate } = useRetrieveMandateToken(tokenLoading);

	const [tabIndex, setTabIndex] = useState(0),
		[stepZero, setStepZero] = useState({
			myself: false,
			employees: false,
			not_paying: false,
			first_payday_date: null,
			first_tax_year_pay: null,
			first_payroll_ever: null,
			current_payroll_provider: ' ',
			inner_step: 0,
		}),
		[stepOne, setStepOne] = useState({
			accounts_office_reference: '',
			employer_paye_reference: '',
			unique_taxpayer_reference: '',
			employment_allowance: false,
			small_employer_relief: false,
			agriculture_sector: false,
			aquaculture_sector: false,
			road_transport_sector: false,
			other_sector: false,
			rti_user_id: '',
			rti_password: '',
			inner_step: 0,
		}),
		[stepTwo, setStepTwo] = useState({
			has_pension_scheme: null,
			pension_selection: '',
			current_pension_provider: ' ',
			other_pension_provider: '',
			current_employer_id: '',
			pension_collection_date: ' ',
			paid_version: false,
			staging_date: employer?.staging_date
				? parseISO(employer?.staging_date)
				: null,
			pension_direct_debit_details: {
				account_holder_name: '',
				account_number: '',
				sort_code: '',
				phone_info: {
					phone: '',
					info: '',
				},
				email: '',
				postcode: '',
				address_line_1: '',
				address_line_2: '',
				address_line_3: '',
				country: '',
			},
			inner_step: 0,
		}),
		[stepThree, setStepThree] = useState({
			has_employer_liability_insurance: false,
			insurance_provider: '',
			insurance_policy_number: '',
			inner_step: 0,
		}),
		[open, setOpen] = useState(false);

	const [openCollegia, setOpenCollegia] = useState(false),
		[collegia, setCollegia] = useState({
			email: '',
			password: '',
		});

	const navigate = useNavigate();

	useEffect(() => {
		if (mandate?.id) {
			setMandateFlowInitiated(false);
			setTokenLoading(false);
		}
	},[mandate]);

	useEffect(() => {
		if (employer?.collegia_emandate && employer?.collegia_emandate?.token_active) {
			setMandateFlowInitiated(true);
			setToken(employer?.collegia_emandate?.token);
		}
	}, [employer?.collegia_emandate]);

	useEffect(() => {
		setTabIndex(employer?.basic_setup_step ?? 0);
		setStepTwo((prevStepTwo) => ({
			...prevStepTwo,
			staging_date: employer?.staging_date
				? parseISO(employer?.staging_date)
				: null,
		}));
		window.scrollTo(0, 0);
	}, [employer?.basic_setup_step, employer?.staging_date]);

	useEffect(() => {
		if (token !== '') {
			setOpen(true);
		}
	}, [token]);

	useEffect(() => {
		if (hasCollegiaAccount) {
			setOpenCollegia(true);
		} else {
			setOpenCollegia(false);
		}
	}, [hasCollegiaAccount]);

	if (
		isBasicLoading ||
		isUserLoading
	) {
		return <Loading />;
	}

	if (
		tokenLoading
	) {
		return (
			<Grid
				container
				justifyContent={"center"}
				alignItems={"center"}
				direction={"column"}
			>
				<Grid
					item
					justifyContent={"center"}
					marginTop={"10%"}
				>
					<Grid marginBottom={4}>
						<StyleTypography
							fontSize={32}
							fontSizeMedium={24}
							fontWeight={"bold"}
						>
							If you already signed your mandate, please wait 10 seconds. If you are not
							<br />redirected automatically, please <a href={"/onboarding/basic-setup"}>click here.</a>
						</StyleTypography>
					</Grid>
					<StyleTypography
						fontSize={32}
						fontSizeMedium={24}
						fontWeight={"bold"}
						color={'red'}
					>
						If you prefer to go back and manually fill the direct debit details, please
						<span
							style={{ cursor: "pointer", marginLeft: "10px", textDecoration: "underline" }}
							onClick={() => {
								Request.put('/api/employers/cancel-mandate-token')
									.then(response => {
										if (response.data.success) {
											setTokenLoading(false);
											setToken('');
											setMandateFlowInitiated(false);
											setOpen(false);
										}

									})
									.catch(error => {
										console.error(error);
									});
							}}
						>
							click here.
						</span>
					</StyleTypography>

				</Grid>
				<Loading />
			</Grid>
		);
	}

	if (mandateFlowInitiated && open && token !== '') {
		return (
			<Dialog
				open={open}
				fullWidth={true}
				maxWidth={'md'}
				id={'direct-debit-guarantee-dialog'}
			>
				<DialogTitle>
					<Grid
						container
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Grid>
							<Typography className={'dialog-title'}>
								We need to set up a Direct Debit to <br />
								collect pension contributions.
							</Typography>
						</Grid>
						<Grid>
							<img
								src={DDLogo}
								alt={'sign up with collegia'}
								className={'dd-logo'}
							/>
						</Grid>
					</Grid>
					<Typography className={'dialog-title-subtitle'}>
						Don’t worry, FreePayroll is free, we will only collect pension contributions.
					</Typography>
				</DialogTitle>
				<DialogContent>
					<ul>
						<li>
							We require your authorisation to collect the outstanding pension
							contributions for your workforce because of Automatic Enrolment.
							This is a UK legal requirement.
						</li>
						<li>
							When you sign the Direct Debit mandate, you understand you grant
							Collegia Partners Limited, which is regulated by the FCA FRN
							922214, the authority to collect those pension contributions from
							your corporate bank account.
						</li>
						<li>
							This Guarantee is offered by all banks and building societies that
							accept instructions to pay Direct Debits.
						</li>
						<li>
							If an error is made in the payment of your Direct Debit by Nuapay
							re Collegia Partners Trustees Limited T/A Collegia or your bank or
							building society, you are entitled to a full and immediate refund
							of the amount paid from your band or building society.
						</li>
						<li>
							If you receive a refund you are not entitled to, you must pay it
							back when Nuapay re Collegia Partners Trustees Limited T/A
							Collegia asks you to.
						</li>
						<li>
							You can cancel a Direct Debit at any time by simply contacting
							your bank or building society. Written confirmation may be
							required. Please also notify us.
						</li>
					</ul>
				</DialogContent>
				<DialogActions>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
							}}
							isWhiteButton={true}
							fullWidth={false}
							onClick={() => {
								Request.put('/api/employers/cancel-mandate-token')
									.then(response => {
										if (response.data.success) {
											setMandateFlowInitiated(false);
											setToken('');
											setTokenLoading(false);
											setOpen(false);
										}
									})
									.catch(error => {
										console.error(error);
									});
							}}
						>
							Cancel
						</StyleButton>
					</Grid>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
							}}
							fullWidth={false}
							onClick={() => {
								let link = '';
								if (process.env.REACT_APP_ENV === 'production') {
									link =
										'https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
										token;
								} else {
									link =
										'https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
										token;
								}
								window.open(link, '_blank');
								setOpen(false);
								setToken('');
								setTokenLoading(true);
							}}
						>
							Continue
						</StyleButton>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Grid
			container
			justifyContent={'center'}
			id={'basic-setup'}
		>
			<StyleAppBar
				customOnClick={() =>
					backLink(
						tabIndex,
						stepZero,
						stepOne,
						stepTwo,
						stepThree,
						setStepZero,
						setStepOne,
						setStepTwo,
						setStepThree,
						navigate
					)
				}
				custom={true}
			/>
			<Grid
				xl={10}
				lg={10}
				md={10}
				sm={10}
				xs={10}
				item
				container
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Tabs
						variant="fullWidth"
						className={'tabs-basic-setup'}
						value={tabIndex}
					>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 0 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'Payroll Dates'}
							value={0}
						/>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 1 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'HMRC Settings'}
							value={1}
						/>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 2 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'Automatic Enrolment'}
							value={2}
						/>
						<Tab
							sx={{ borderBottom: '5px solid transparent' }}
							label={'Compliance'}
							value={3}
						/>
					</Tabs>
				</Grid>
				{_renderSteps(
					tabIndex,
					stepZero,
					setStepZero,
					stepOne,
					setStepOne,
					stepTwo,
					setStepTwo,
					stepThree,
					setStepThree,
					setTabIndex,
					basicSetup,
					user
				)}
			</Grid>
			<Dialog
				onClose={() => setOpenCollegia(false)}
				open={openCollegia}
				fullWidth={true}
				maxWidth={'sm'}
				id={'signup-collegia'}
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					className={'dialog-close-button-container'}
				>
					<CloseIcon
						sx={{
							fontSize: '1.6vw',
						}}
						className={'dialog-close-button'}
						onClick={() => {
							setCollegia({
								email: '',
								password: '',
							});
							setOpenCollegia(false);
						}}
					/>
				</Grid>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					textAlign={'center'}
				>
					<img
						src={collegiaLogo}
						alt={'sign up with collegia'}
						className={'collegia-logo'}
					/>
				</Grid>
				<DialogTitle>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={22}
						fontWeight={'bold'}
						textAlign={'center'}
					>
						This company is already registered with Collegia.
					</StyleTypography>
					<StyleTypography
						fontSize={20}
						fontSizeMedium={20}
						color={'#000000'}
						textAlign={'center'}
					>
						Login with your Collegia account to continue:
					</StyleTypography>
				</DialogTitle>
				<DialogContent>
					<Grid
						item
						container
						xl={10}
						lg={10}
						md={10}
						sm={10}
						xs={10}
						justifyContent={'center'}
						rowSpacing={2}
					>
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<InputLabel className={'default-input-label'}>Email*</InputLabel>
							<TextField
								className={'default-text-field'}
								fullWidth
								required
								value={collegia.email}
								onChange={(e) =>
									setCollegia({
										...collegia,
										email: e.target.value,
									})
								}
							/>
						</Grid>

						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<InputLabel className={'default-input-label'}>
								Password*
							</InputLabel>
							<TextField
								className={'default-text-field'}
								fullWidth
								required
								type={'password'}
								value={collegia.password}
								onChange={(e) =>
									setCollegia({
										...collegia,
										password: e.target.value,
									})
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						item
						container
						xl={10}
						lg={10}
						md={10}
						sm={10}
						xs={10}
						justifyContent={'center'}
						rowSpacing={2}
					>
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<FreepayrollButton
								onClick={() => {
									importCollegiaData({
										employers: {
											...collegia,
										},
									});
								}}
								variant={"primary"}
								isLoading={isImportingCollegia}
							>
								Login
							</FreepayrollButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default BasicSetup;
